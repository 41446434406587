@media only screen and (max-width: 980px) {
    html, body {
        zoom: 150%;
    }
}

html, body {
    height: 100%;
    width: 100%;
}

MyForm {
    display: block;
}
.uuid{
    display: none;
}
#conversational-form > div > cf-input > cf-input-control-elements > cf-list {
    max-width: 100%;
}
#conversational-form > div > cf-chat > scrollable > div > cf-chat-response.user.can-edit.peak-thumb.show {
    color: #000;
}